import { configureStore } from '@reduxjs/toolkit'
import { customerApi } from './reducers.js/customers'
import { setupListeners } from '@reduxjs/toolkit/query'
import navbarReducer from './reducers.js/navbar'
import authReducer from './reducers.js/auth'
import userReducer from './reducers.js/user'
import businessReducer from './reducers.js/business'
import { serviceApi } from './reducers.js/services'
import { appointmentsApi } from './reducers.js/appointments'
import { staffUserApi } from './reducers.js/staffUsers'
import { signUpApi } from './reducers.js/signUp'

export const store = configureStore({
  reducer: {
    navbar: navbarReducer,
    auth: authReducer,
    user: userReducer,
    business: businessReducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [appointmentsApi.reducerPath]: appointmentsApi.reducer,
    [staffUserApi.reducerPath]: staffUserApi.reducer,
    [signUpApi.reducerPath]: signUpApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([customerApi.middleware, serviceApi.middleware, appointmentsApi.middleware, staffUserApi.middleware, signUpApi.middleware]),
})

setupListeners(store.dispatch)