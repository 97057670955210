import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function SelectText({ label, value, setValue, options, helperText, disabled, customStyle = {} }) {

    const formattedOptions = options.map(opt => ({ label: opt.name, value: opt.id }))

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                '& .MuiPopover-paper': { height: '400px' }
            }}
            noValidate
            autoComplete="off">
            <TextField
                id={label}
                select
                style={customStyle}
                label={label}
                defaultValue={value}
                value={value}
                disabled={disabled}
                onChange={row => setValue(row.target.value)}
                helperText={helperText || ''}>
                {formattedOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
}