import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { colors } from '../../utils/device';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    width: '80%',
    maxHeight: '90vh',
    overflow: 'scroll',
    bgcolor: 'white',
    border: `2px solid ${colors.greyText}`,
    borderRadius: '12px',
    // boxShadow: 24,
    p: 4,
};

export default function BasicModal({ open, setOpen, children }) {

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}>
            <Box sx={style}>
                {children}
            </Box>
        </Modal>
    );
}