import styled from "styled-components";
import { device } from "../../utils/device";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    @media ${device.mobile}{
        justify-content: center;
    }
`