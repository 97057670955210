export const inputOnlyNumbers = (text, wholeNumbers) => {
    if (!text) {
        return true
    }
    else if (text.includes('.') && wholeNumbers) {
        return false
    } else if (text === '.') {
        return false
    } else if (text.split('.').length > 2) {
        return false
    } else if (/^[1-9]\d*(\.\d+)?$/.test(text)) {
        return true
    } else {
        return false
    }
}