import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Components/Navbar";
import { Body, BodyPadding } from "../../utils/commonStyles";
import { Paper, TextField } from "@mui/material";
import { useFetchUserQuery } from "../../utils/redux/reducers.js/signUp";
import { setBusiness } from "../../utils/redux/reducers.js/business";
import { setUser } from "../../utils/redux/reducers.js/user";
import Loading from "../../Components/Loading";
import CustomizedSnackbars from "../../Components/SnackBar";
import { colors } from "../../utils/device";

const Dashboard = () => {
    const open = useSelector((state) => state.navbar.value)
    const token = useSelector((state) => state.auth.value)
    const business = useSelector((state) => state.business.value)

    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    const dispatch = useDispatch()

    const { data, error, isLoading } = useFetchUserQuery('', { skip: !token })

    useEffect(() => {
        if (data && data.data) {
            const { user, business } = data.data
            dispatch(setBusiness(business))
            dispatch(setUser(user))
            setLoading(false)
        }
    }, [data])

    useEffect(() => {
        if (error) {
            setLoading(false)
            setErrorMessage(error ? (error.error || error.data.err) : error)
        }
    }, [error])

    return (<Body open={open}>
        <Navbar />
        <BodyPadding>
            <Loading open={isLoading || loading} />
            {errorMessage && <CustomizedSnackbars type={'error'} message={errorMessage} clearMessage={setErrorMessage} />}

            <Paper elevation={3} style={{ backgroundColor: 'white', borderRadius: '10px', padding: '20px 15px' }}>
                {business && <label>Booking Url: <span style={{ color: colors.greyText }}> <a href={`https://app.bookaclub.com?mId=${business.mId}`} target="_blank">{`https://app.bookaclub.com?mId=${business.mId}`}</a></span> </label>}
            </Paper>
        </BodyPadding>
        {/* <Table rows={rows} columns={columns} /> */}
        {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </Body>)
}

export default Dashboard