import React, { useEffect } from "react";
import { Blocks, Content, LogoIcon, Wrapper } from "./styles";
import { useDispatch, useSelector } from 'react-redux'

import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LocalLibraryRoundedIcon from '@mui/icons-material/LocalLibraryRounded';
import BookOnlineRoundedIcon from '@mui/icons-material/BookOnlineRounded';

import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "../../firebase-config";
import { setToken } from "../../utils/redux/reducers.js/auth";
import { toggle } from "../../utils/redux/reducers.js/navbar";

const auth = getAuth(app);
const Sidebar = () => {

    const open = useSelector((state) => state.navbar.value)
    const dispatch = useDispatch()

    useEffect(() => {
        onAuthStateChanged(auth, user => {
            if (!user) {
                window.location.href = "/login"
            } else {
                auth.currentUser.getIdToken().then(idToken => {
                    // console.log('idToken', idToken)
                    dispatch(setToken(idToken))
                }).catch(e => {
                    console.error('login error', e)
                })
            }
        })
    }, [])

    const iconStyle = { color: 'white', width: '25px', height: '25px', marginRight: '10px' }

    const menuOptions = [
        { name: 'Dashboard', icon: <DashboardRoundedIcon style={iconStyle} />, url: '/' },
        { name: 'Appointments', icon: <BookOnlineRoundedIcon style={iconStyle} />, url: '/appointments' },
        { name: 'Calendar', icon: <CalendarMonthRoundedIcon style={iconStyle} />, url: '/calendar' },
        { name: 'Services', icon: <LocalLibraryRoundedIcon style={iconStyle} />, url: '/services' },
        // { name: 'Customisation', icon: DashboardRoundedIcon },
        { name: 'Customers', icon: <GroupRoundedIcon style={iconStyle} />, url: '/customers' },
        { name: 'Settings', icon: <SettingsRoundedIcon style={iconStyle} />, url: '/settings' },
    ]


    const navigate = useNavigate();

    return (<Wrapper open={open}>
        <Content>
            <LogoIcon src={require('../../assets/Logo.png')} />
            {menuOptions.map(({ name, icon, url }, ind) => <Blocks key={name} selected={url === window.location.pathname} onClick={() => { navigate(url); window.screen.width < 1000 && dispatch(toggle()) }}>
                {icon}
                <label>{name}</label>
            </Blocks>)}
        </Content>
    </Wrapper>)
}

export default Sidebar