const desktop = '(min-width : 1200px)'
const tablet = '(min-width : 576px) and (max-width : 1199px)'
const mobile = '(max-width : 575px)'

export const device = {
    desktop,
    tablet,
    mobile,
}

export const colors = {
    navIcon: '#7b809a',
    greyText: '#878c93',
    hoverGrey: '#f8f8fb',
    tableBlue: "#cde4ff",
    textBlue: '#037aff'
}