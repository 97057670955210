import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { eachMinuteOfInterval, endOfDay, format, startOfDay } from 'date-fns';


export default function SelectTextFields({ time, label, setTime, options, helperText, disabled }) {
    const timeOptions = eachMinuteOfInterval({ start: startOfDay(new Date(time)), end: endOfDay(new Date(time)) }, { step: 5 })
    const timeFormattedOptions = timeOptions.map(val => ({ value: format(val, 'hh:mm a'), label: format(val, 'hh:mm a') }))

    const updateTime = (val) => {
        const index = timeFormattedOptions.findIndex(v => v.value === val)
        setTime(timeOptions[index])
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                '& .MuiPopover-paper': { height: '400px' }
            }}
            noValidate
            autoComplete="off" >
            <TextField
                id={label}
                select
                label={label}
                defaultValue={time}
                value={format(time, 'hh:mm a')}
                disabled={disabled}
                onChange={(row) => updateTime(row.target.value)}
                helperText={helperText || ""}>
                {timeFormattedOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
}