import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Components/Navbar";
import { Body, BodyPadding, PaperModified, SectionTitle } from "../../utils/commonStyles";
import { useFetchCustomerQuery } from "../../utils/redux/reducers.js/customers";
import { Button, TextField } from "@mui/material";
import TimelineCalendar from "../../Components/CalendarBlock";
import { addDays, addMinutes, differenceInMinutes, eachDayOfInterval, format, isBefore, startOfDay, subDays } from "date-fns";
import BasicModal from "../../Components/Modal";

import SelectTextFields from "../../Components/Select";
import SelectText from "../../Components/SelectText";
import { useFetchServiceQuery } from "../../utils/redux/reducers.js/services";
import CustomersCheckboxes from "../../Components/CustomersCheckbox";
import { useAddAppointmentMutation, useDeleteAppointmentMutation, useFetchAppointmentsQuery, useUpdateAppointmentMutation } from "../../utils/redux/reducers.js/appointments";
import CustomizedSnackbars from "../../Components/SnackBar";
import { convertMinsToHours } from '../../utils/time'
import Loading from "../../Components/Loading";

const Calendar = () => {
    const open = useSelector((state) => state.navbar.value)
    const token = useSelector((state) => state.auth.value)
    const business = useSelector((state) => state.business.value)

    const [startDate, setStartDate] = useState(startOfDay(new Date()))
    const [endDate, setEndDate] = useState(addDays(startOfDay(new Date()), 6))
    const [showAddAppointment, setShowAddAppointment] = useState(false)
    const [fieldError, setFieldError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [mode, setMode] = useState('')
    const [appointmentBlocks, setAppointmentBlocks] = useState([])

    const [appointmentDate, setAppointmentDate] = useState(new Date())
    const [endAppointmentDate, setEndAppointmentDate] = useState()
    const [appointmentType, setAppointmentType] = useState('')

    const [customersRecords, setCustomersRecords] = useState([])
    const [successMessage, setSuccessMessage] = useState('')

    const [currentLocation, setCurrentLocation] = useState('')

    const { data, error, isLoading } = useFetchAppointmentsQuery(business && business.mId, { skip: !token || !business || !currentLocation })

    const { data: servicesData, error: ServicesError, isLoading: servicesLoading } = useFetchServiceQuery(business && business.mId, { skip: !token || !business })
    const { data: customersData, error: CustomersError, isLoading: customersLoading } = useFetchCustomerQuery(business && business.mId, { skip: !token || !business })

    const [addAppointment, { error: errorAdd, data: dataAdd, isLoading: isAddingAppointment }] = useAddAppointmentMutation()
    const [updateAppointment, { error: errorUpdate, data: dataUpdate, isLoading: isUpdatingAppointment }] = useUpdateAppointmentMutation()
    const [deleteAppointment, { error: errorDelete, data: dataDelete, isLoading: isDeletingAppointment }] = useDeleteAppointmentMutation()

    const locations = business && business.locations


    useEffect(() => {
        if (data && data.data) {
            setAppointmentBlocks(data.data.map(({ id, appointmentType, customersAttending, appointmentDate, endAppointmentDate, }) => ({ id, appointmentType, customersAttending, appointmentDate, appointmentTitle: findMatchingSericeName(appointmentType), endAppointmentDate, blockCount: parseInt(differenceInMinutes(new Date(endAppointmentDate), new Date(appointmentDate)) / 5) })))
        }
    }, [data, servicesData, customersData])

    useEffect(() => {
        if (business) {
            business.locations.length > 0 && setCurrentLocation(business.locations[0].id)
        }
    }, [business])

    useEffect(() => {
        if (dataAdd && showAddAppointment) {
            setShowAddAppointment(false)
            setSuccessMessage('Appointment created successfully.')
        }
    }, [dataAdd])

    useEffect(() => {
        if (dataUpdate && showAddAppointment) {
            setShowAddAppointment(false)
            setSuccessMessage('Appointment updated successfully.')
        }
    }, [dataUpdate])

    useEffect(() => {
        if (dataDelete && showAddAppointment) {
            setShowAddAppointment(false)
            setSuccessMessage('Appointment deleted successfully.')
        }
    }, [dataDelete])

    useEffect(() => {
        if (!showAddAppointment) {
            setMode('')
            setEndAppointmentDate()
            setAppointmentType('')

        }
    }, [showAddAppointment])

    useEffect(() => {
        if (mode) {
            const matchingAppointment = appointmentBlocks.find(({ id }) => id === mode)
            setEndAppointmentDate(new Date(matchingAppointment.endAppointmentDate))
            setAppointmentType(matchingAppointment.appointmentType)
            setCustomersRecords(customersRecords.map(customer => ({ ...customer, attending: !!matchingAppointment.customersAttending.find(({ id }) => id === customer.id)?.attending, present: !!matchingAppointment.customersAttending.find(({ id }) => id === customer.id)?.present })))
        }
    }, [mode])

    useEffect(() => {
        if (error) {
            setErrorMessage(error ? (error.error || error.data.err) : error)
        }
    }, [error])

    useEffect(() => {
        if (errorAdd) {
            setErrorMessage(errorAdd ? (errorAdd.error || errorAdd.data.err) : errorAdd)
        }
    }, [errorAdd])

    useEffect(() => {
        if (errorUpdate) {
            setErrorMessage(errorUpdate ? (errorUpdate.error || errorUpdate.data.err) : errorUpdate)
        }
    }, [errorUpdate])

    useEffect(() => {
        if (errorDelete) {
            setErrorMessage(errorDelete ? (errorDelete.error || errorDelete.data.err) : errorDelete)
        }
    }, [errorDelete])

    useEffect(() => {
        if (CustomersError) {
            setErrorMessage(CustomersError ? (CustomersError.error || CustomersError.data.err) : CustomersError)
        }
    }, [CustomersError])

    useEffect(() => {
        if (ServicesError) {
            setErrorMessage(ServicesError ? (ServicesError.error || ServicesError.data.err) : ServicesError)
        }
    }, [ServicesError])

    useEffect(() => {
        if (customersData && customersData.data && !mode) {
            setCustomersRecords(customersData.data)
        }
    }, [customersData, showAddAppointment, mode])

    useEffect(() => {
        if (appointmentType && servicesData && servicesData.data && appointmentDate) {
            const matchingService = servicesData.data.find(({ id }) => id === appointmentType)
            matchingService && setEndAppointmentDate(addMinutes(appointmentDate, matchingService.duration))
        }
    }, [appointmentType, servicesData, appointmentDate])

    const findMatchingSericeName = (appointmentId) => {
        if (servicesData && servicesData.data) {
            const matchingService = servicesData.data.find(({ id }) => id === appointmentId)
            if (matchingService) {
                return matchingService.name
            }
            return ''
        } else {
            return ''
        }

    }

    const handleDeleteAppointment = () => {
        deleteAppointment({ id: mode, mId: business.mId })
    }

    const handleAddAppointment = () => {

        setFieldError('')
        setErrorMessage('')


        if (!appointmentType) {
            setErrorMessage('Service name is required.')
            return
        }

        const payload = {
            appointmentDate,
            appointmentType,
            endAppointmentDate,
            customersAttending: customersRecords.map(({ id, attending, present }) => ({ id, attending, present }))
        }

        if (mode) {
            updateAppointment({
                mId: business.mId,
                id: mode,
                data: payload
            })
        } else {
            addAppointment({
                mId: business.mId,
                data: payload
            })
        }
    }

    return (<Body open={open}>
        <Navbar />
        <BodyPadding style={{ height: '100%', minWidth: 'calc(100% - 40px)' }}>
            <Loading open={isLoading || isAddingAppointment || isUpdatingAppointment || isDeletingAppointment || servicesLoading || customersLoading} />
            {errorMessage && !fieldError && <CustomizedSnackbars type={'error'} message={errorMessage} clearMessage={setErrorMessage} />}
            {successMessage && <CustomizedSnackbars type={'success'} message={successMessage} clearMessage={setSuccessMessage} />}

            <BasicModal open={showAddAppointment} setOpen={setShowAddAppointment}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SectionTitle>{mode ? 'Edit' : 'Add'} an Appointment</SectionTitle>
                    {mode &&
                        <Button variant='outlined' color="error" onClick={() => handleDeleteAppointment()}>Delete</Button>
                    }
                </div>

                <SelectText value={startOfDay(appointmentDate).toISOString()} setValue={v => setAppointmentDate(new Date(v))} options={eachDayOfInterval({ start: subDays(appointmentDate, 10), end: addDays(appointmentDate, 10) }).map(v => ({ name: format(v, 'dd MMM yyyy'), id: v.toISOString() }))} label="Service Date" helperText={''} />

                <div style={{ display: 'flex' }}>
                    <SelectTextFields time={appointmentDate} setTime={setAppointmentDate} label="Start time" helperText={`${format(appointmentDate, 'dd MMM yyyy hh:mm a')}`} />
                    {servicesData && servicesData.data && <SelectText value={appointmentType} setValue={setAppointmentType} options={servicesData.data} label="Service name" helperText={appointmentType ? `${convertMinsToHours(servicesData.data.find(({ id }) => id === appointmentType)?.duration || 0)}` : ''} />}
                </div>
                {endAppointmentDate && <SelectTextFields time={endAppointmentDate} disabled setTime={setEndAppointmentDate} label="End Date (automatically filled)" helperText={format(endAppointmentDate, 'dd MMM yyyy hh:mm a')} />}

                <CustomersCheckboxes customers={customersRecords.map(({ firstName, lastName, id, email, attending, present }) => ({ id, firstName, lastName, email, attending: !!attending, present }))} setCustomers={setCustomersRecords} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant='outlined' onClick={() => setShowAddAppointment(false)}>Cancel</Button>
                    <Button variant='contained' onClick={handleAddAppointment}>Save</Button>
                </div>
            </BasicModal>

            <PaperModified elevation={3} style={{ height: '100%', minWidth: 'calc(100% - 40px)' }}>
                <TimelineCalendar
                    appointmentBlocks={appointmentBlocks}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    locations={locations}
                    currentLocation={currentLocation}
                    setCurrentLocation={setCurrentLocation}
                    setEndDate={setEndDate}
                    setAppointmentDate={setAppointmentDate}
                    setMode={setMode}
                    setShowAddAppointment={setShowAddAppointment} />
            </PaperModified>
        </BodyPadding>
    </Body>)
}

export default Calendar