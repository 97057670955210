import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: null,
  },
  tagTypes: ['User'],
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload
      // return action.payload
    },
    clearUser: (state, action) => {
      state.value = null
      // return null
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser, clearUser } = userSlice.actions

export default userSlice.reducer