import { Button } from '@mui/material'
import { addDays, format } from 'date-fns'
import React from 'react'
import { BlockRow } from './styles'

const ToggleDate = ({ startDate, endDate, setStartDate, setEndDate }) => {

    const switchDates = (increment) => {
        const dateDifference = increment ? 7 : -7
        setStartDate(addDays(startDate, dateDifference))
        setEndDate(addDays(endDate, dateDifference))
    }

    return (<BlockRow>
        <Button variant='outlined' style={{ marginRight: '5px' }} onClick={() => switchDates(false)}>{'<'}</Button>
        <Button variant='outlined' onClick={() => switchDates(false)}>{format(startDate, 'dd')} - {format(endDate, 'dd MMM yyyy')}</Button>
        <Button variant='outlined' style={{ marginLeft: '5px' }} onClick={() => switchDates(true)}>{'>'}</Button>
    </BlockRow>)
}

export default ToggleDate