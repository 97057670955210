import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "../../Components/Navbar";
import Loading from "../../Components/Loading";
import { Body, BodyPadding, PaperModified, SectionTitle } from "../../utils/commonStyles";
import { serviceApi, useFetchServiceQuery, useAddServiceMutation, useUpdateServiceMutation, useDeleteServiceMutation } from "../../utils/redux/reducers.js/services";

import { getCurrencyFromCountry } from '../../utils/currency'
import { convertMinsToHours } from '../../utils/time'
import { inputOnlyNumbers } from '../../utils/inputFormatter'

import { Duration, Price, Row, Title } from "./styles";
import { Button, duration, TextField } from "@mui/material";
import BasicModal from "../../Components/Modal";
import InputBlock from "../../Components/InputBlock";
import CustomizedSnackbars from "../../Components/SnackBar";

const Services = () => {
    const open = useSelector((state) => state.navbar.value)
    const token = useSelector((state) => state.auth.value)
    const business = useSelector((state) => state.business.value)

    const { data, error, isLoading } = useFetchServiceQuery(business && business.mId, { skip: !token || !business })

    const [mode, setMode] = useState('')
    const [showAddService, setShowAddService] = useState(false)
    const [serviceName, setServiceName] = useState('')
    const [serviceDescription, setServiceDescription] = useState('')
    const [serviceDuration, setServiceDuration] = useState(120)
    const [seatsAvailable, setSeatsAvailable] = useState('')
    const [price, setPrice] = useState('')
    const [fieldError, setFieldError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const [successMessage, setSuccessMessage] = useState('')

    const [updateService, { error: errorUpdate, isLoading: loadingUpdateService, data: dataUpdate }] = useUpdateServiceMutation()
    const [addService, { error: errorAdd, isLoading: loadingAddService, data: dataAdd }] = useAddServiceMutation()
    const [deleteService, { error: errorDelete, isLoading: loadingDeleteService, data: dataDelete }] = useDeleteServiceMutation()

    const servicesData = data && data.data

    const handleAddService = () => {

        setFieldError('')
        setErrorMessage('')

        // "name": "Service 1",
        // "description": "",
        // "terms": "",
        // "hasLimitedCustomers": true,
        // "customerLimit": 10,
        // "staff": [],
        // "resouces": [],
        // "duration": 100,
        // "mId": "test",
        // "price": 120,
        // "country": "Australia",

        if (!serviceName) {
            setFieldError('service-name')
            setErrorMessage('Service name is required.')
            return
        }

        if (!serviceDescription) {
            setFieldError('service-desc')
            setErrorMessage('Service description is required.')
            return
        }

        if (!price || !parseInt(price)) {
            setFieldError('price')
            setErrorMessage('Price is required.')
            return
        }
        if (!serviceDuration || !parseInt(serviceDuration)) {
            setFieldError('duration')
            setErrorMessage('Duration is required.')
            return
        }

        const payload = {
            name: serviceName,
            description: serviceDescription,
            hasLimitedCustomers: !!seatsAvailable,
            customerLimit: seatsAvailable,
            price: parseFloat(price).toFixed(2),
            duration: parseInt(serviceDuration),
            staff: [],
            resources: []
        }

        if (mode) {
            updateService({
                mId: business.mId,
                id: mode,
                data: payload
            })
        } else {

            addService({
                mId: business.mId,
                data: payload
            })
        }
    }

    useEffect(() => {
        if (dataAdd && showAddService) {
            setShowAddService(false)
            setSuccessMessage('Service created successfully.')
        }
    }, [dataAdd])

    useEffect(() => {
        if (dataUpdate && showAddService) {
            setShowAddService(false)
            setSuccessMessage('Service updated successfully.')
        }
    }, [dataUpdate])

    useEffect(() => {
        if (dataDelete && showAddService) {
            setShowAddService(false)
            setSuccessMessage('Service deleted successfully.')
        }
    }, [dataDelete])

    useEffect(() => {
        if (!showAddService) {
            fieldError && setFieldError('')
            errorMessage && setErrorMessage('')
            setServiceName('')
            setServiceDescription('')
            setPrice('')
            setServiceDuration('')
            setSeatsAvailable('')
        }
    }, [dataAdd])

    useEffect(() => {
        if (error) {
            setErrorMessage(error ? (error.error || error.data.err) : error)
        }
    }, [error])

    useEffect(() => {
        if (errorAdd) {
            setErrorMessage(errorAdd ? (errorAdd.error || errorAdd.data.err) : errorAdd)
        }
    }, [errorAdd])

    useEffect(() => {
        if (errorUpdate) {
            setErrorMessage(errorUpdate ? (errorUpdate.error || errorUpdate.data.err) : errorUpdate)
        }
    }, [errorUpdate])

    useEffect(() => {
        if (errorDelete) {
            setErrorMessage(errorDelete ? (errorDelete.error || errorDelete.data.err) : errorDelete)
        }
    }, [errorDelete])

    const updateModalWithValues = (row) => {
        setServiceName(row.name)
        setServiceDescription(row.description)
        setPrice(row.price)
        setServiceDuration(row.duration)
        setSeatsAvailable(row.hasLimitedCustomers ? row.customerLimit : '')
        setShowAddService(true)
    }

    const handleDeleteService = () => {
        deleteService({ id: mode, mId: business.mId })
    }

    return (<Body open={open}>
        <Navbar />
        {errorMessage && !fieldError && <CustomizedSnackbars type={'error'} message={errorMessage} clearMessage={setErrorMessage} />}
        {successMessage && <CustomizedSnackbars type={'success'} message={successMessage} clearMessage={setSuccessMessage} />}
        <BasicModal open={showAddService} setOpen={setShowAddService}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <SectionTitle>{mode ? 'Edit' : 'Add'} a Service</SectionTitle>
                {mode &&
                    <Button variant='outlined' color="error" onClick={() => handleDeleteService()}>Delete</Button>
                }
            </div>
            <InputBlock error={fieldError === 'service-name'} errorMessage={errorMessage} required label='Service name' id={'service-name'} value={serviceName} setValue={setServiceName} />
            <TextField error={fieldError === 'service-desc'} helperText={fieldError === 'service-desc' && errorMessage} multiline minRows={3} id="service-desc" required label="Service description" variant="outlined" style={{ marginBottom: '40px' }}
                value={serviceDescription} onChange={(e) => { setServiceDescription(e.target.value) }} />
            <InputBlock error={fieldError === 'price'} errorMessage={errorMessage} label={`Price ${getCurrencyFromCountry('Australia')}`} required id={'price'} type="number" value={price} setValue={v => inputOnlyNumbers(v) && setPrice(v)} />
            <InputBlock error={fieldError === 'duration'} errorMessage={errorMessage} label={`Duration (mins)`} required id={'duration'} type="number" value={serviceDuration} setValue={v => inputOnlyNumbers(v, true) && setServiceDuration(v ? parseInt(v) : '')} />
            <InputBlock error={fieldError === 'seats'} errorMessage={errorMessage} label={`Total Seats`} id={'seats'} type="number" placeholder={'Leave blank if unlimited.'} value={seatsAvailable} setValue={v => inputOnlyNumbers(v, true) && setSeatsAvailable(v ? parseInt(v) : '')} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant='outlined' onClick={() => setShowAddService(false)}>Cancel</Button>
                <Button variant='contained' onClick={handleAddService}>Save</Button>
            </div>
        </BasicModal>

        <BodyPadding>
            <Loading open={isLoading || loadingUpdateService || loadingAddService || loadingDeleteService} />
            <PaperModified elevation={3}>
                <Row noHover>
                    <SectionTitle>Services</SectionTitle>
                    <Button variant="contained" onClick={() => { setMode(''); setShowAddService(true) }}>+</Button>
                </Row>
                {servicesData && servicesData.map(resp => <Row key={resp.id} onClick={() => { setMode(resp.id); updateModalWithValues(resp) }}>
                    <Title>{resp.name}</Title>
                    <div>
                        <Price>{getCurrencyFromCountry(resp.country)}{resp.price}</Price>
                        <Duration>{convertMinsToHours(resp.duration)}</Duration>
                    </div>
                </Row>)}
            </PaperModified>
        </BodyPadding>
        {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </Body >)
}

export default Services