import styled, { css } from "styled-components";
import { colors } from "../../utils/device";

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #dcdcdc;
    align-items: center;
    ${props => !props.noHover && css`
        :hover{
            cursor: pointer;
            background-color: ${colors.hoverGrey};
        }
    `}
`