import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

export default function CustomersCheckboxes({ customers, setCustomers, isMarkingAttendance }) {

    // customers=[{ name, attending: true, id, email}]
    // const [state, setState] = React.useState(customers);

    const handleChange = (event) => {
        if (isMarkingAttendance) {
            setCustomers([...customers.map(({ firstName, lastName, present, attending, id, email }) => id === event.target.name ? ({ id, firstName, lastName, attending, present: !present, email }) : ({ id, firstName, lastName, attending, present, email }))])
        } else {
            setCustomers([...customers.map(({ firstName, lastName, attending, id, email }) => id === event.target.name ? ({ id, firstName, lastName, attending: !attending, email }) : ({ id, firstName, lastName, attending, email }))])
        }
    }

    const error = false

    return (
        <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" style={{ width: '100%' }} error={error}>
                <FormLabel component="legend">Customers Attending:</FormLabel>
                {customers.length === 0 && isMarkingAttendance && <FormHelperText style={{ fontSize: '15px' }}>No one attending!</FormHelperText>}
                {!isMarkingAttendance && <FormHelperText component="legend" style={{ fontSize: '13px', color: 'red' }} >Check off customers only if already paid (outside our flow), otherwise dont touch this!</FormHelperText>}
                <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '400px', overflow: 'scroll' }}>
                    <FormGroup>
                        {customers.map(({ firstName, lastName, present, attending, id, email }, index) => <FormControlLabel key={id + index}
                            control={<Checkbox checked={isMarkingAttendance ? present : attending} onChange={handleChange} name={id} />}
                            label={firstName + ' ' + lastName + ` (${email})`} />)}
                    </FormGroup>
                </div>
                {!isMarkingAttendance && <FormHelperText style={{ fontSize: '13px' }}>This list will be automatically updated when customers signup and pay to the service.</FormHelperText>}
            </FormControl>
        </Box>
    );
}