import Sidebar from './Components/Sidebar';
import Dashboard from './Pages/Dashboard';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Customers from './Pages/Customers';
import Services from './Pages/Services';
import Calendar from './Pages/Calendar';
import Settings from './Pages/Settings';
import Appointments from './Pages/Appointments';
import Login from './Pages/Login';

const returnElement = (element) => <>
  {window.location.pathname !== '/login' && <Sidebar />}
  {element}
</>
const router = createBrowserRouter([
  {
    path: "/",
    element: returnElement(<Dashboard />),
  },
  {
    path: "/login",
    element: returnElement(<Login />),
  },
  {
    path: "/customers",
    element: returnElement(<Customers />),
  },
  {
    path: "/services",
    element: returnElement(<Services />),
  },
  {
    path: "/appointments",
    element: returnElement(<Appointments />),
  },
  {
    path: "/calendar",
    element: returnElement(<Calendar />),
  },
  {
    path: "/settings",
    element: returnElement(<Settings />),
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App;
