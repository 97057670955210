import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Components/Navbar";
import { Body, BodyPadding, PaperModified, SectionTitle } from "../../utils/commonStyles";
import { useFetchCustomerQuery } from "../../utils/redux/reducers.js/customers";
import { Button, TextField } from "@mui/material";
import { addDays, addMinutes, differenceInMinutes, eachDayOfInterval, format, isBefore, startOfDay, subDays } from "date-fns";
import BasicModal from "../../Components/Modal";

import SelectTextFields from "../../Components/Select";
import SelectText from "../../Components/SelectText";
import { useFetchServiceQuery } from "../../utils/redux/reducers.js/services";
import CustomersCheckboxes from "../../Components/CustomersCheckbox";
import { useFetchAppointmentsQuery, useUpdateAppointmentMutation } from "../../utils/redux/reducers.js/appointments";
import CustomizedSnackbars from "../../Components/SnackBar";
import Loading from "../../Components/Loading";
import { DateBlock, Row, TimeBlock, Title } from "./styles";

const Appointments = () => {
    const open = useSelector((state) => state.navbar.value)
    const token = useSelector((state) => state.auth.value)
    const business = useSelector((state) => state.business.value)
    const user = useSelector((state) => state.user.value)

    const [fieldError, setFieldError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const [appointmentBlocks, setAppointmentBlocks] = useState([])

    const [customersRecords, setCustomersRecords] = useState([])
    const [successMessage, setSuccessMessage] = useState('')

    const [showAppointmentInfo, setShowAppointmentInfo] = useState(false)

    const [currentLocation, setCurrentLocation] = useState('')

    const [matchingAppointment, setMatchingAppointment] = useState()

    const { data, error, isLoading } = useFetchAppointmentsQuery(business && business.mId, { skip: !token || !business || !currentLocation })

    const { data: servicesData, error: ServicesError, isLoading: servicesLoading } = useFetchServiceQuery(business && business.mId, { skip: !token || !business })
    const { data: customersData, error: CustomersError, isLoading: customersLoading } = useFetchCustomerQuery(business && business.mId, { skip: !token || !business })
    const [updateAppointment, { error: errorUpdate, data: dataUpdate, isLoading: isUpdatingAppointment }] = useUpdateAppointmentMutation()


    useEffect(() => {
        if (data && data.data) {
            const filteredAppointments = data.data.filter(({ ownerId }) => user.role === 'owner' || user.id === ownerId)
            setAppointmentBlocks(filteredAppointments.map(({ id, appointmentType, customersAttending, appointmentDate, endAppointmentDate, }) => ({ id, appointmentType, customersAttending, appointmentDate, ...findMatchingServiceName(appointmentType), endAppointmentDate })).sort((a, b) => new Date(b.appointmentDate).getTime() - new Date(a.appointmentDate).getTime()))
        }
    }, [data, servicesData, customersData])

    useEffect(() => {
        if (business) {
            business.locations.length > 0 && setCurrentLocation(business.locations[0].id)
        }
    }, [business])

    useEffect(() => {
        if (error) {
            setErrorMessage(error ? (error.error || error.data.err) : error)
        }
    }, [error])

    useEffect(() => {
        if (dataUpdate && showAppointmentInfo) {
            setShowAppointmentInfo(false)
            setSuccessMessage('Appointment updated successfully.')
            setMatchingAppointment()
        }
    }, [dataUpdate])

    useEffect(() => {
        if (errorUpdate) {
            setErrorMessage(errorUpdate ? (errorUpdate.error || errorUpdate.data.err) : errorUpdate)
        }
    }, [errorUpdate])


    useEffect(() => {
        if (CustomersError) {
            setErrorMessage(CustomersError ? (CustomersError.error || CustomersError.data.err) : CustomersError)
        }
    }, [CustomersError])

    useEffect(() => {
        if (ServicesError) {
            setErrorMessage(ServicesError ? (ServicesError.error || ServicesError.data.err) : ServicesError)
        }
    }, [ServicesError])

    useEffect(() => {
        if (showAppointmentInfo) {
            const localMatchingAppointment = appointmentBlocks.find(({ id }) => id === showAppointmentInfo)
            setCustomersRecords(customersRecords.map(customer => ({ ...customer, attending: !!localMatchingAppointment.customersAttending.find(({ id }) => id === customer.id)?.attending, present: !!localMatchingAppointment.customersAttending.find(({ id }) => id === customer.id)?.present })).filter(({ attending }) => attending))
            setMatchingAppointment(localMatchingAppointment)
        }
    }, [showAppointmentInfo])

    useEffect(() => {
        if (customersData && customersData.data && !showAppointmentInfo) {
            setCustomersRecords(customersData.data)
        }
    }, [customersData, showAppointmentInfo])


    const findMatchingServiceName = (appointmentId) => {
        if (servicesData && servicesData.data) {
            const matchingService = servicesData.data.find(({ id }) => id === appointmentId)
            if (matchingService) {
                const { ownerId, userId } = matchingService
                return { appointmentTitle: matchingService.name, ownerId, userId }
            }
            return {}
        } else {
            return {}
        }
    }


    const handleUpdateAppointment = () => {

        const payload = {
            appointmentDate: matchingAppointment.appointmentDate,
            appointmentType: matchingAppointment.appointmentType,
            endAppointmentDate: matchingAppointment.endAppointmentDate,
            customersAttending: customersRecords.map(({ id, attending, present }) => ({ id, attending, present }))
        }

        updateAppointment({
            mId: business.mId,
            id: showAppointmentInfo,
            data: payload
        })
    }

    return (<Body open={open}>
        <Navbar />
        <BodyPadding style={{ height: '100%', minWidth: 'calc(100% - 40px)' }}>
            <Loading open={isLoading || servicesLoading || customersLoading || isUpdatingAppointment} />
            {errorMessage && !fieldError && <CustomizedSnackbars type={'error'} message={errorMessage} clearMessage={setErrorMessage} />}
            {successMessage && <CustomizedSnackbars type={'success'} message={successMessage} clearMessage={setSuccessMessage} />}

            <BasicModal open={!!showAppointmentInfo} setOpen={setShowAppointmentInfo}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SectionTitle>Appointment Info</SectionTitle>
                    {matchingAppointment && <div>
                        <DateBlock>{format(new Date(matchingAppointment.appointmentDate), 'dd/MMM/yyy')}</DateBlock>
                        <TimeBlock>{format(new Date(matchingAppointment.appointmentDate), 'hh:mm a')}-{format(new Date(matchingAppointment.endAppointmentDate), ' hh:mm a')}</TimeBlock>
                    </div>}

                </div>

                <CustomersCheckboxes isMarkingAttendance customers={customersRecords.map(({ firstName, lastName, id, email, attending, present }) => ({ id, firstName, lastName, email, attending, present: !!present }))} setCustomers={setCustomersRecords} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant='outlined' onClick={() => setShowAppointmentInfo(false)}>Cancel</Button>
                    <Button variant='contained' onClick={() => handleUpdateAppointment()}>Save</Button>
                </div>
            </BasicModal>


            <PaperModified elevation={3}>
                <Row noHover>
                    <SectionTitle>Appointments</SectionTitle>
                </Row>
                {appointmentBlocks && appointmentBlocks.map(resp => <Row key={resp.id} onClick={() => setShowAppointmentInfo(resp.id)}>
                    <Title>{resp.appointmentTitle}</Title>
                    <div>
                        <DateBlock>{format(new Date(resp.appointmentDate), 'dd/MMM/yyy')}</DateBlock>
                        <TimeBlock>{format(new Date(resp.appointmentDate), 'hh:mm a')}-{format(new Date(resp.endAppointmentDate), ' hh:mm a')}</TimeBlock>
                    </div>
                </Row>)}
            </PaperModified>

        </BodyPadding>
    </Body>)
}

export default Appointments