

export const convertMinsToHours = mins => {
    let message = `${mins} mins`

    if (mins >= 60) {
        let hours = parseInt(mins / 60)
        let minsRem = mins - (hours * 60)
        message = `${hours > 1 ? `${hours} hrs` : `${hours} hr`}` + `${minsRem > 0 ? ` ${minsRem} mins` : ''}`
    }
    return message
}