import styled from "styled-components";
import { device } from "../../utils/device";

export const Wrapper = styled.div`
    display: flex;
    width: 250px;
    box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
    background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
    padding: 20px;
    position: fixed;
    left: ${props => props.open ? 0 : '-290px'};
    top: 0;
    bottom: 0;
    z-index: 111;
    @media ${device.tablet}{
        left: ${props => props.open ? 0 : '-290px'};
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 100%;
    height: 100%;
`

export const Blocks = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
    padding: 10px;
    background-color:${props => props.selected ? 'rgba(255, 255, 255, 0.2)' : 'transparent'};
    img{
        width: 36px;
        height: 36px;
        margin-right: 20px;
    }
    label{
        color: white;
        font-size: 14px;
    }
    :hover{
        background-color: rgba(255, 255, 255, 0.2);
        cursor: pointer;
    }
`

export const LogoIcon = styled.img`
    width: 200px;
    margin: 10px auto;
`