import { css } from "@emotion/react"
import styled from "styled-components"
import { colors } from "../../utils/device";

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #dcdcdc;
    align-items: center;
    :hover{
        cursor: pointer;
        background-color: ${colors.hoverGrey};
    }
`

export const DateBlock = styled.p`
    font-weight: 700;
    text-align: right;
    margin: 0;
`
export const Title = styled.p`
    font-weight: 500;
    margin: 0;
`

export const TimeBlock = styled.p`
    font-weight: 500;
    text-align: right;
    color: ${colors.greyText};
    margin: 0;
`