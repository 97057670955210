import styled, { css } from "styled-components";
import { colors, device } from "./device";
import { Paper } from "@mui/material";

export const Body = styled.div`
    overflow: hidden;
    vertical-align: top;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    flex-grow: 1;
    transition: all 0.3s ease 0s;
    margin-right: 0px;
    margin-left: ${props => props.open ? '290px' : 0};
    @media ${device.mobile}, ${device.tablet}{
        margin-left: 0;
    }
`

export const BodyPadding = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: scroll;
`

export const PaperModified = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
`

export const SectionTitle = styled.h1`
    display: flex;
    font-size: 28px;
    font-weight: 800;
    line-height: 36px;
`

export const Subtitle = styled.h3`
    display: flex;
    font-size: 21px;
    font-weight: 600;
`

export const Cell = styled.td`
    border: 1px solid ${props => props.info ? 'white' : `${colors.greyText}20`};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    font-size: 14px;
    ${props => props.isRow && css`
        :hover{
            background-color: ${colors.tableBlue} !important;
            color: ${colors.textBlue} !important;
        }
    `}
`