import styled, { css } from "styled-components";
import { device } from "../../utils/device";


export const Wrapper = styled.div`
    z-index: 96;
    height: 64px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px;
    margin-bottom: 50px;
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 8px 20px 8px 18px;
    background: rgb(255, 255, 255);
    @media ${device.mobile}, ${device.tablet}{
        padding-left: ${props => props.open ? '300px' : '20px'};
    }
`