// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

    apiKey: "AIzaSyDvnlju5co-gD_GH29ORA0QJ4zEBRCGh08",

    authDomain: "bookaclub-com.firebaseapp.com",

    projectId: "bookaclub-com",

    storageBucket: "bookaclub-com.appspot.com",

    messagingSenderId: "567244240866",

    appId: "1:567244240866:web:8c9cee270232397fe1abbb",

    measurementId: "G-0RQQ4P8QSN"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics, app }