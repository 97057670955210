import { InputAdornment, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { getPhonePrefixFromCountry } from '../../utils/currency';

export default function InputBlock({ id, label, multiline, minRows, country, required, placeholder, value, setValue, type = 'text', error, errorMessage, customStyle = {} }) {

    useEffect(() => {
        if (error && errorMessage) {
            setTimeout(() => {
                document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
            }, [500])
        }
    }, [error, errorMessage])


    return (
        <TextField id={id} multiline={multiline} minRows={minRows} InputProps={type === 'tel' ? {
            startAdornment: <InputAdornment position="start">{getPhonePrefixFromCountry(country)}</InputAdornment>,
        } : {}} error={error} helperText={error && errorMessage} required={required} label={label} variant="outlined" style={{ marginBottom: '40px', ...customStyle }}
            type={type} placeholder={placeholder}
            inputProps={(type === 'tel' || type === 'number') ? { min: 0, inputmode: 'numeric', pattern: "[0-9]*" } : {}}
            onChange={(e) => setValue(e.target.value)} value={value} />
    );
}