import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "../../Components/Navbar";
import Loading from "../../Components/Loading";
import { Body, BodyPadding, PaperModified, SectionTitle } from "../../utils/commonStyles";
import { customerApi, useFetchCustomerQuery, useAddCustomerMutation, useUpdateCustomerMutation, useDeleteCustomerMutation } from "../../utils/redux/reducers.js/customers";

import { getCurrencyFromCountry, getPhonePrefixFromCountry } from '../../utils/currency'
import { inputOnlyNumbers } from '../../utils/inputFormatter'

import { Row, } from "./styles";
import { Button, duration, TextField } from "@mui/material";
import BasicModal from "../../Components/Modal";
import InputBlock from "../../Components/InputBlock";
import CustomizedSnackbars from "../../Components/SnackBar";
import DataTable from "../../Components/Table";
import { emailValidationRegex } from "../../utils/constants";

const Customers = () => {
    const open = useSelector((state) => state.navbar.value)
    const token = useSelector((state) => state.auth.value)
    const business = useSelector((state) => state.business.value)

    const { data, error, isLoading } = useFetchCustomerQuery(business && business.mId, { skip: !token || !business })

    const [mode, setMode] = useState('')
    const [showAddCustomer, setShowAddCustomer] = useState(false)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [notes, setNotes] = useState('')

    const [fieldError, setFieldError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const [successMessage, setSuccessMessage] = useState('')

    const [updateCustomer, { error: errorUpdate, isLoading: loadingUpdateCustomer, data: dataUpdate }] = useUpdateCustomerMutation()
    const [addCustomer, { error: errorAdd, isLoading: loadingAddCustomer, data: dataAdd }] = useAddCustomerMutation()
    const [deleteCustomer, { error: errorDelete, isLoading: loadingDeleteCustomer, data: dataDelete }] = useDeleteCustomerMutation()


    const customersData = (data && data.data) ? data.data : []

    const handleAddCustomer = () => {

        setFieldError('')
        setErrorMessage('')


        if (!firstName) {
            setFieldError('first-name')
            setErrorMessage('First name is required.')
            return
        }

        if (!lastName) {
            setFieldError('last-name')
            setErrorMessage('Last name is required.')
            return
        }

        if (!email) {
            setFieldError('email')
            setErrorMessage('Email is required.')
            return
        }

        if (!email.match(emailValidationRegex)) {
            setFieldError('email')
            setErrorMessage('Invalid email.')
            return
        }

        if (!phone) {
            setFieldError('phone')
            setErrorMessage('Phone is required.')
            return
        }

        if (phone.length !== 9 || phone[0] !== '4') {
            setFieldError('phone')
            setErrorMessage('Invalid Phone number.')
            return
        }

        const payload = {
            firstName,
            lastName,
            email,
            phone,
            notes,
            country: 'Australia'
        }

        if (mode) {
            updateCustomer({
                mId: business.mId,
                id: mode,
                data: payload
            })
        } else {
            addCustomer({
                mId: business.mId,
                data: payload
            })
        }
    }

    useEffect(() => {
        if (dataAdd && showAddCustomer) {
            setShowAddCustomer(false)
            setSuccessMessage('Customer created successfully.')
        }
    }, [dataAdd])

    useEffect(() => {
        if (dataUpdate && showAddCustomer) {
            setShowAddCustomer(false)
            setSuccessMessage('Customer updated successfully.')
        }
    }, [dataUpdate])

    useEffect(() => {
        if (dataDelete && showAddCustomer) {
            setShowAddCustomer(false)
            setSuccessMessage('Customer deleted successfully.')
        }
    }, [dataDelete])

    useEffect(() => {
        if (!showAddCustomer) {
            fieldError && setFieldError('')
            errorMessage && setErrorMessage('')
            setFirstName('')
            setLastName('')
            setEmail('')
            setPhone('')
            setNotes('')
        }
    }, [dataAdd, showAddCustomer])

    useEffect(() => {
        if (error) {
            setErrorMessage(error ? (error.error || error.data.err) : error)
        }
    }, [error])

    useEffect(() => {
        if (errorAdd) {
            setErrorMessage(errorAdd ? (errorAdd.error || errorAdd.data.err) : errorAdd)
        }
    }, [errorAdd])

    useEffect(() => {
        if (errorUpdate) {
            setErrorMessage(errorUpdate ? (errorUpdate.error || errorUpdate.data.err) : errorUpdate)
        }
    }, [errorUpdate])

    useEffect(() => {
        if (errorDelete) {
            setErrorMessage(errorDelete ? (errorDelete.error || errorDelete.data.err) : errorDelete)
        }
    }, [errorDelete])

    const updateModalWithValues = ({ row }) => {
        setMode(row.id)
        setFirstName(row.firstName)
        setLastName(row.lastName)
        setEmail(row.email)
        setPhone(row.phone)
        setNotes(row.notes)
        setShowAddCustomer(true)
    }

    const handleDeleteCustomer = () => {
        deleteCustomer({ id: mode, mId: business.mId })
    }

    const generateWidth = (divisor) => {
        const windowWidth = (window.innerWidth - (open ? 270 : 0))
        if (windowWidth < 576) {
            return divisor === 3 ? 250 : 150
        } else {
            return windowWidth / divisor
        }
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'Name', headerName: 'Name', width: generateWidth(3),
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        // { field: 'lastName', headerName: 'Last name', width: 130 },
        {
            field: 'phone', headerName: 'Phone', width: generateWidth(4),
            valueGetter: (params) =>
                `${getPhonePrefixFromCountry(params.row.country)} ${params.row.phone || ''}`,
        },
        { field: 'email', headerName: 'Email', width: generateWidth(3) },
        // {
        //     field: 'age',
        //     headerName: 'Age',
        //     type: 'number',
        //     width: 90,
        // },
        // {
        //     field: 'fullName',
        //     headerName: 'Full name',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 160,
        // },
    ]

    return (<Body open={open}>
        <Navbar />

        {errorMessage && !fieldError && <CustomizedSnackbars type={'error'} message={errorMessage} clearMessage={setErrorMessage} />}
        {successMessage && <CustomizedSnackbars type={'success'} message={successMessage} clearMessage={setSuccessMessage} />}
        <BasicModal open={showAddCustomer} setOpen={setShowAddCustomer}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <SectionTitle>{mode ? 'Edit' : 'Add'} a Customer</SectionTitle>
                {mode &&
                    <Button variant='outlined' color="error" onClick={() => handleDeleteCustomer()}>Delete</Button>
                }
            </div>
            <InputBlock error={fieldError === 'first-name'} errorMessage={errorMessage} required label='First Name' id={'first-name'} value={firstName} setValue={setFirstName} />
            <InputBlock error={fieldError === 'last-name'} errorMessage={errorMessage} required label='Last Name' id={'last-name'} value={lastName} setValue={setLastName} />
            <InputBlock error={fieldError === 'email'} type="email" errorMessage={errorMessage} required label='Email' id={'email'} value={email} setValue={setEmail} />
            <InputBlock error={fieldError === 'phone'} placeholder={'4xxxxxxxx'} country="Australia" type="tel" errorMessage={errorMessage} required label='Phone' id={'phone'} value={phone} setValue={setPhone} />

            <TextField error={fieldError === 'notes'} helperText={fieldError === 'notes' && errorMessage} multiline minRows={3} id="notes" label="Notes" variant="outlined" style={{ marginBottom: '40px' }}
                value={notes} onChange={(e) => { setNotes(e.target.value) }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant='outlined' onClick={() => setShowAddCustomer(false)}>Cancel</Button>
                <Button variant='contained' onClick={handleAddCustomer}>Save</Button>
            </div>
        </BasicModal>

        <BodyPadding>

            <Loading open={isLoading || loadingUpdateCustomer || loadingAddCustomer || loadingDeleteCustomer} />
            <PaperModified elevation={3}>
                <Row noHover>
                    <SectionTitle>Customers</SectionTitle>
                    <Button variant="contained" onClick={() => { setMode(''); setShowAddCustomer(true) }}>+</Button>
                </Row>
                <DataTable rows={customersData} columns={columns} onRowClick={updateModalWithValues} />
            </PaperModified>

        </BodyPadding>
    </Body>)
}

export default Customers