import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    value: null,
  },
  tagTypes: ['Auth'],
  reducers: {
    setToken: (state, action) => {
      state.value = action.payload
      // return action.payload
    },
    clearToken: (state, action) => {
      state.value = null
      // return null
    },
  },
})

// Action creators are generated for each case reducer function
export const { setToken, clearToken } = authSlice.actions

export default authSlice.reducer