import React, { useEffect } from "react";
import { Content, Wrapper } from "./styles";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { colors } from "../../utils/device";

import { useDispatch, useSelector } from 'react-redux'
import { toggle } from '../../utils/redux/reducers.js/navbar'
import { useFetchUserQuery } from "../../utils/redux/reducers.js/signUp";
import { setBusiness } from "../../utils/redux/reducers.js/business";
import { setUser } from "../../utils/redux/reducers.js/user";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
    const open = useSelector((state) => state.navbar.value)
    const token = useSelector((state) => state.auth.value)
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { data, error, isLoading } = useFetchUserQuery('', { skip: !token })

    useEffect(() => {
        if (data && data.data) {
            const { user, business } = data.data
            dispatch(setBusiness(business))
            dispatch(setUser(user))
        }
    }, [data])

    useEffect(() => {
        if (error) {
            navigate('/')
        }
    }, [error])

    return (<Wrapper>
        <Content open={open}>
            <MenuRoundedIcon style={{ color: colors.navIcon }} onClick={() => dispatch(toggle())} />
        </Content>
    </Wrapper>)
}

export default Navbar