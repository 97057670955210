import { createSlice } from '@reduxjs/toolkit'

export const businessSlice = createSlice({
  name: 'business',
  initialState: {
    value: null,
  },
  tagTypes: ['Business'],
  reducers: {
    setBusiness: (state, action) => {
      state.value = action.payload
      // return action.payload
    },
    clearBusiness: (state, action) => {
      state.value = null
      // return null
    },
  },
})

// Action creators are generated for each case reducer function
export const { setBusiness, clearBusiness } = businessSlice.actions

export default businessSlice.reducer