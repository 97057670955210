import DataTable from "../Table"
import { addHours, addMinutes, differenceInMinutes, eachDayOfInterval, eachHourOfInterval, eachMinuteOfInterval, endOfDay, format, getDay, isBefore, startOfDay } from 'date-fns'
import { useEffect, useState } from "react"
import { colors } from "../../utils/device";
import { Cell } from "../../utils/commonStyles";
import ToggleDate from "../ToggleDate";
import SelectText from "../SelectText";
import { Wrapper } from "./styles";
import { dayIndexToDay } from "../../utils/constants";

const TimelineCalendar = ({ startDate, endDate, setStartDate, setEndDate, locations, currentLocation, setCurrentLocation, appointmentBlocks, setAppointmentDate, setShowAddAppointment, setMode }) => {


    const [columns, setColumns] = useState([])
    const [appointmentBlocksModified, setAppointmentBlocksModified] = useState([])

    const rows = eachMinuteOfInterval({ start: startDate, end: endOfDay(startDate) }, { step: 5 }).map((v, ind) => ({ value: v, id: ind }))

    const currentLocationObj = locations && locations.find(({ id }) => id === currentLocation)
    const timings = currentLocationObj && currentLocationObj.timings
    const columnsWithDayType = columns.map(({ field }) => ({ field, day: dayIndexToDay[getDay(new Date(field))] }))

    const timingsOfDay = timings && columnsWithDayType.map(({ day, field }, index) => ({ colIndex: index, day, field, closed: timings.find(time => time.day === day).closed, start: timings.find(time => time.day === day).start, end: timings.find(time => time.day === day).end }))

    useEffect(() => {
        if (appointmentBlocks && columns.length > 0 && rows.length > 0) {
            setAppointmentBlocksModified(appointmentBlocks.map(({ appointmentDate, blockCount, ...rest }) => ({ colStartIndex: getMatchingColIndex(appointmentDate), rowStartIndex: getMatchingRowIndex(appointmentDate), appointmentDate, blockCount, ...rest })))
        }
    }, [appointmentBlocks, columns])

    useEffect(() => {
        // Scroll to current time on first load
        const now = new Date()
        const rowIndex = getMatchingRowIndex(now)
        document.getElementById(`0-${rowIndex}`) && document.getElementById(`0-${rowIndex}`).scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, [columns])


    const clearExistingAppointments = () => {
        const allExistingBlocks = document.querySelectorAll('[data-appointment-id]')
        if (allExistingBlocks.length > 0) {
            for (let block of allExistingBlocks) {
                block.style.border = `1px solid ${colors.greyText}20`;
                block.style.backgroundColor = 'transparent';
                block.removeAttribute('appointmentId')
                block.removeAttribute('data-appointment-id')
                block.innerHTML = block.dataset.prevVal
                block.style.color = 'transparent'
            }
        }
    }


    useEffect(() => {
        if (appointmentBlocksModified && appointmentBlocksModified.length > 0) {

            clearExistingAppointments()

            for (let block of appointmentBlocksModified) {
                const { id, colStartIndex, rowStartIndex, blockCount, appointmentTitle, appointmentType } = block;

                const idList = [...Array(blockCount).keys()].map((v, idx) => (rowStartIndex + idx > rows.length) ? ((colStartIndex + 1) + '-' + (rowStartIndex + idx - rows.length)) : (colStartIndex + '-' + (rowStartIndex + idx)))
                for (let eachId of idList) {
                    const matchingStartBlock = document.getElementById(eachId)
                    if (matchingStartBlock) {
                        matchingStartBlock.dataset.appointmentId = id
                        matchingStartBlock.dataset.prevVal = matchingStartBlock.innerHTML
                        if (eachId === idList[0]) {
                            matchingStartBlock.style.borderTop = `1px solid ${colors.textBlue}`;
                        }
                        if (eachId === idList[idList.length - 1]) {
                            matchingStartBlock.style.borderBottom = `1px solid ${colors.textBlue}`;
                        }
                        matchingStartBlock.style.borderLeft = `1px solid ${colors.textBlue}`;
                        matchingStartBlock.style.borderRight = `1px solid ${colors.textBlue}`;
                        matchingStartBlock.style.backgroundColor = colors.tableBlue;

                        if (idList[Math.floor(idList.length / 2)] === eachId) {
                            matchingStartBlock.style.color = colors.textBlue
                            matchingStartBlock.innerHTML = appointmentTitle
                        }
                    }
                }
            }
        }
    }, [appointmentBlocksModified])

    useEffect(() => {
        if (startDate && endDate) {
            setColumns([
                ...eachDayOfInterval({ start: startDate, end: endDate }).map((item, index) => ({ field: item.toISOString(), fullDate: format(item, 'dd/MMM/yyyy'), headerName: format(item, 'dd/MMM'), width: generateWidth(eachDayOfInterval({ start: startDate, end: endDate }).length + 1) }))
            ])
        }
    }, [startDate, endDate, appointmentBlocks])

    const getMatchingColIndex = date => {
        return columns.findIndex(({ fullDate }) => fullDate === format(new Date(date), 'dd/MMM/yyyy'))
    }

    const getMatchingRowIndex = date => {
        return parseInt(differenceInMinutes(new Date(date), startOfDay(new Date(date))) / 5)
    }

    const generateWidth = (divisor) => {
        const windowWidth = window.innerWidth
        if (windowWidth < 576) {
            return 175
        } else {
            return (windowWidth / divisor) - divisor
        }
    }

    const checkIfExistingAppointmentPresent = (col, index, id) => {
        const appointmentId = document.getElementById(id).dataset.appointmentId
        if (appointmentId) {
            const appointmentStartDate = new Date(appointmentBlocks.find(row => row.id === appointmentId).appointmentDate)
            setAppointmentDate(appointmentStartDate)
            setMode(appointmentId)
        } else {
            setMode('')
            setAppointmentDate(addMinutes(new Date(col.field), 5 * index))
        }
        setShowAddAppointment(true)
    }

    const setBgColorCell = (colIndex, rowIndex) => {

        if (!timingsOfDay) {
            return 'white'
        } else {
            const matchingTimeBlock = timingsOfDay.find(time => time.colIndex === colIndex)
            if (!matchingTimeBlock.closed && rowIndex * 5 >= matchingTimeBlock.start && rowIndex * 5 <= matchingTimeBlock.end) {
                return 'white'
            } else {
                return '#dcdcdc60'
            }
        }
    }

    return (
        <>
            <Wrapper>
                {locations && <SelectText value={currentLocation} setValue={setCurrentLocation} options={locations} label="Location name" helperText={''} />}
                <ToggleDate startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
            </Wrapper>

            <div style={{ display: 'flex', height: '100%', overflow: 'scroll' }}>
                <table style={{ display: 'flex', flexDirection: 'column' }}>
                    <thead style={{ display: 'flex' }}>
                        <tr style={{ display: 'flex' }}>
                            <Cell style={{ width: '100px', color: 'transparent' }} info>{'timestamp'}</Cell>
                            {columns.map(col => <Cell key={col.field} style={{ width: col.width, color: colors.greyText }}>{col.headerName}</Cell>)}
                        </tr>
                    </thead>
                    <tbody style={{ height: '100%', overflow: 'scroll' }}>
                        {rows.map((row, index) =>
                            <tr key={row.id} style={{ display: 'flex' }}>
                                <Cell info key={row.id} style={{ display: 'flex', color: colors.greyText, alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: '100px' }}>{format(row.value, 'hh: mm a')}</Cell>
                                {columns.map((col, idx) => <Cell isRow id={idx + '-' + index} key={col.field + row.id} style={{ width: col.width, color: 'transparent', backgroundColor: setBgColorCell(idx, index) }} onClick={() => { checkIfExistingAppointmentPresent(col, index, idx + '-' + index) }}>
                                    {format(addMinutes(new Date(col.field), 5 * index), 'hh:mm a')}
                                </Cell>)}
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </>)
}

export default TimelineCalendar