import { createSlice } from '@reduxjs/toolkit'

export const navbarSlice = createSlice({
    name: 'menuIcon',
    initialState: {
        value: window.innerWidth > 575 ? true : false,
    },
    reducers: {
        toggle: (state) => {
            state.value = !state.value
        },
    },
})

// Action creators are generated for each case reducer function
export const { toggle } = navbarSlice.actions

export default navbarSlice.reducer