import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Components/Navbar";
import { Body, BodyPadding, PaperModified, SectionTitle, Subtitle } from "../../utils/commonStyles";
import { Button, TextField } from "@mui/material";
import Table from '../../Components/Table'
import Loading from "../../Components/Loading";
import { useAddStaffUserMutation, useDeleteStaffUserMutation, useFetchStaffUsersQuery, useUpdateStaffUserMutation } from "../../utils/redux/reducers.js/staffUsers";
import BasicTabs from "../../Components/Tabs";
import DataTable from "../../Components/Table";
import CustomizedSnackbars from "../../Components/SnackBar";
import BasicModal from "../../Components/Modal";
import InputBlock from "../../Components/InputBlock";
import { Row } from "../Services/styles";
import SelectText from "../../Components/SelectText";
import { emailValidationRegex, newLocationOpeningHours, timeslotOptions } from "../../utils/constants";
import { useUpdateBusinessMutation, useUpdateResourcesMutation } from "../../utils/redux/reducers.js/signUp";
import { generateRandomString } from "../../utils/common";
import ControlledSwitches from "../../Components/Switch";

const Settings = () => {
    const open = useSelector((state) => state.navbar.value)
    const token = useSelector((state) => state.auth.value)
    const business = useSelector((state) => state.business.value)

    const tabOptions = ['Business', 'Staff', 'Resources']
    const [currentTab, setCurrentTab] = useState(0)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('')
    const [mode, setMode] = useState('')
    const [showAddStaff, setShowAddStaff] = useState(false)
    const [showAddResource, setShowAddResource] = useState(false)
    const [showAddLocation, setShowAddLocation] = useState(false)

    const [fieldError, setFieldError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [currentLocation, setCurrentLocation] = useState(0)

    const { data, error, isLoading } = useFetchStaffUsersQuery(business && business.mId, { skip: !token || !business })

    const [updateStaff, { error: errorUpdate, data: dataUpdate, isLoading: isUpdatingStaff }] = useUpdateStaffUserMutation()
    const [addStaff, { error: errorAdd, data: dataAdd, isLoading: isAddingStaff }] = useAddStaffUserMutation()
    const [deleteStaff, { error: errorDelete, data: dataDelete, isLoading: isDeletingStaff }] = useDeleteStaffUserMutation()

    const [updateBusiness, { error: errorBusiness, data: dataBusiness, isLoading: isUpdatingBusiness }] = useUpdateBusinessMutation()
    const [updateResources, { error: errorResources, data: dataResources, isLoading: isUpdatingResources }] = useUpdateResourcesMutation()

    const [businessName, setBusinessName] = useState('')
    const [businessDescription, setBusinessDescription] = useState('')
    const [locations, setLocations] = useState([])
    const [enableEditingLocation, setEnableEditingLocation] = useState(false)

    const [resources, setResources] = useState([])
    const [resourceName, setResourceName] = useState('')
    const [resourceDescription, setResourceDescription] = useState('')

    const [locationName, setLocationName] = useState('')
    const [locationAddress, setLocationAddress] = useState('')
    const [timings, setTimings] = useState(newLocationOpeningHours) //Used for location timings


    useEffect(() => {
        if (business) {
            setBusinessName(business.name)
            setBusinessDescription(business.description)
            setResources(business.resources || [])
            setLocations(business.locations || [])
        }
    }, [business])

    useEffect(() => {
        if (errorBusiness) {
            setErrorMessage(errorBusiness ? (errorBusiness.error || errorBusiness.data.err) : errorBusiness)
        }
    }, [errorBusiness])

    useEffect(() => {
        if (errorResources) {
            setErrorMessage(errorResources ? (errorResources.error || errorResources.data.err) : errorResources)
        }
    }, [errorResources])

    const handleAddStaff = () => {

        setFieldError('')
        setErrorMessage('')


        if (!firstName) {
            setFieldError('first-name')
            setErrorMessage('First name is required.')
            return
        }

        if (!lastName) {
            setFieldError('last-name')
            setErrorMessage('Last name is required.')
            return
        }

        if (!email) {
            setFieldError('email')
            setErrorMessage('Email is required.')
            return
        }

        if (!email.match(emailValidationRegex)) {
            setFieldError('email')
            setErrorMessage('Invalid email.')
            return
        }

        if (!role) {
            setFieldError('')
            setErrorMessage('Role is required.')
            return
        }

        const payload = {
            firstName,
            lastName,
            email,
            role
        }

        if (mode) {
            updateStaff({
                mId: business.mId,
                id: mode,
                data: payload
            })
        } else {
            addStaff({
                mId: business.mId,
                data: payload
            })
        }
    }

    useEffect(() => {
        if (dataBusiness) {
            setSuccessMessage('Business updated successfully.')
            enableEditingLocation && setEnableEditingLocation(false)
            showAddLocation && setShowAddLocation(false)
        }
    }, [dataBusiness])

    useEffect(() => {
        if (dataResources) {
            setShowAddResource(false)
            setSuccessMessage('Resources updated successfully.')
        }
    }, [dataResources])

    useEffect(() => {
        if (dataAdd && showAddStaff) {
            setShowAddStaff(false)
            setSuccessMessage('Customer created successfully.')
        }
    }, [dataAdd])

    useEffect(() => {
        if (dataUpdate && showAddStaff) {
            setShowAddStaff(false)
            setSuccessMessage('Customer updated successfully.')
        }
    }, [dataUpdate])

    useEffect(() => {
        if (dataDelete && showAddStaff) {
            setShowAddStaff(false)
            setSuccessMessage('Customer deleted successfully.')
        }
    }, [dataDelete])

    useEffect(() => {
        if (!showAddStaff) {
            fieldError && setFieldError('')
            errorMessage && setErrorMessage('')
            setFirstName('')
            setLastName('')
            setEmail('')
            setRole('')
        }
    }, [showAddStaff, dataAdd])

    useEffect(() => {
        if (!showAddResource) {
            setResourceName('')
            setResourceDescription('')
        }
    }, [showAddResource])

    useEffect(() => {
        if (error) {
            setErrorMessage(error ? (error.error || error.data.err) : error)
        }
    }, [error])

    useEffect(() => {
        if (errorAdd) {
            setErrorMessage(errorAdd ? (errorAdd.error || errorAdd.data.err) : errorAdd)
        }
    }, [errorAdd])

    useEffect(() => {
        if (errorUpdate) {
            setErrorMessage(errorUpdate ? (errorUpdate.error || errorUpdate.data.err) : errorUpdate)
        }
    }, [errorUpdate])

    useEffect(() => {
        if (errorDelete) {
            setErrorMessage(errorDelete ? (errorDelete.error || errorDelete.data.err) : errorDelete)
        }
    }, [errorDelete])

    useEffect(() => {
        if (enableEditingLocation) {
            setLocationAddress(locations[currentLocation] ? locations[currentLocation].address : '')
        }
    }, [enableEditingLocation])

    useEffect(() => {
        if (enableEditingLocation) {
            setEnableEditingLocation(false)
        }
    }, [currentLocation])


    const handleDeleteStaff = () => {
        deleteStaff({ id: mode, mId: business.mId })
    }


    const generateWidth = (divisor) => {
        const windowWidth = (window.innerWidth - (open ? 270 : 0))
        if (windowWidth < 576) {
            return divisor === 3 ? 250 : 150
        } else {
            return windowWidth / divisor
        }
    }

    const columns = [
        {
            field: 'Name', headerName: 'Name', width: generateWidth(3),
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        { field: 'email', headerName: 'Email', width: generateWidth(3) },
        { field: 'role', headerName: 'Role', width: generateWidth(4) },
    ];

    const resourceColumns = [
        {
            field: 'name', headerName: 'Name', width: generateWidth(3),
        },
        { field: 'description', headerName: 'Description', width: generateWidth(4) },
    ];

    const staffData = (data && data.data) ? data.data : []

    const updateModalWithValues = ({ row }) => {
        if (row.role !== 'owner') {
            setMode(row.id)
            setFirstName(row.firstName)
            setLastName(row.lastName)
            setEmail(row.email)
            setRole(row.role)
            setShowAddStaff(true)
        } else {
            setErrorMessage('Cannot change owner details.')
        }
    }

    const updateResourceModalWithValues = ({ row }) => {
        setMode(row.id)
        setResourceName(row.name)
        setResourceDescription(row.description)
        setShowAddResource(true)
    }

    const handleAddResource = () => {
        if (!mode) {
            updateResources({ mId: business.mId, data: { resources: [...resources, { id: generateRandomString(10), name: resourceName, description: resourceDescription }] } })
        } else {
            const modifiedResources = resources.map(res => res.id === mode ? ({ id: res.id, name: resourceName, description: resourceDescription }) : res)
            updateResources({ mId: business.mId, data: { resources: modifiedResources } })
        }
    }

    const handleDeleteResource = () => {
        const modifiedResources = resources.filter(res => res.id !== mode)
        updateResources({ mId: business.mId, data: { resources: modifiedResources } })
    }

    const updateLocationTime = (day, timeType, time) => {
        if (showAddLocation) {
            let newTimings = timings.map(timeslots => {
                if (timeslots.day === day) {
                    return ({ ...timeslots, [timeType]: time })
                }
                return timeslots
            })
            setTimings(newTimings)
        } else {
            let newLocations = locations.map((loc, index) => {
                if (index === currentLocation) {
                    return ({
                        ...loc, timings: loc.timings.map(timeslots => {
                            if (timeslots.day === day) {
                                return ({ ...timeslots, [timeType]: time })
                            }
                            return timeslots
                        })
                    })
                }
                return loc
            })
            setLocations([...newLocations])
        }
    }


    const updateLocationAddress = (value) => {
        let newLocations = locations.map((loc, index) => {
            if (index === currentLocation) {
                return ({
                    ...loc, address: value,
                })
            }
            return loc
        })
        setLocations([...newLocations])
    }

    const toggleTiming = (day, status) => {
        if (showAddLocation) {
            let newTimings = timings.map(timeslots => {
                if (timeslots.day === day) {
                    return ({ ...timeslots, closed: !status })
                }
                return timeslots
            })
            setTimings(newTimings)
        } else {
            let newLocations = locations.map((loc, index) => {
                if (index === currentLocation) {
                    return ({
                        ...loc, timings: loc.timings.map(timeslots => {
                            if (timeslots.day === day) {
                                return ({ ...timeslots, closed: !status })
                            }
                            return timeslots
                        })
                    })
                }
                return loc
            })
            setLocations([...newLocations])
        }
    }

    const handleUpdateLocations = () => {
        updateBusiness({ mId: business.mId, id: business.id, data: { name: businessName, description: businessDescription, locations } })
    }

    const handleAddNewLocation = () => {

        setFieldError('')
        setErrorMessage('')


        if (!locationName) {
            setFieldError('location-name')
            setErrorMessage('Location name is required.')
            return
        }

        if (!locationAddress) {
            setFieldError('location-address')
            setErrorMessage('Location address is required.')
            return
        }

        if (timings.filter(({ closed }) => !closed).length === 0) {
            setErrorMessage('Location should be open atleast on one day.')
            return
        }

        updateBusiness({ mId: business.mId, id: business.id, data: { name: businessName, description: businessDescription, locations: [...locations, { id: generateRandomString(10), name: locationName, address: locationAddress, timings: timings }] } })
    }

    return (<Body open={open}>
        <Navbar />
        <BodyPadding>

            {errorMessage && !fieldError && <CustomizedSnackbars type={'error'} message={errorMessage} clearMessage={setErrorMessage} />}
            {successMessage && <CustomizedSnackbars type={'success'} message={successMessage} clearMessage={setSuccessMessage} />}

            <Loading open={isLoading || isUpdatingStaff || isUpdatingBusiness || isUpdatingResources || isDeletingStaff || isAddingStaff} />

            <BasicModal open={showAddStaff} setOpen={setShowAddStaff}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SectionTitle>{mode ? 'Edit' : 'Add'} a Staff User</SectionTitle>
                    {mode &&
                        <Button variant='outlined' color="error" onClick={() => handleDeleteStaff()}>Delete</Button>
                    }
                </div>
                <InputBlock error={fieldError === 'first-name'} errorMessage={errorMessage} required label='First Name' id={'first-name'} value={firstName} setValue={setFirstName} />
                <InputBlock error={fieldError === 'last-name'} errorMessage={errorMessage} required label='Last Name' id={'last-name'} value={lastName} setValue={setLastName} />
                <InputBlock error={fieldError === 'email'} type="email" errorMessage={errorMessage} required label='Email' id={'email'} value={email} setValue={setEmail} />
                <SelectText value={role} disabled={role === 'owner'} setValue={setRole} options={role === 'owner' ? [{ name: 'Staff', id: 'staff' }, { name: 'Owner', id: 'owner' }] : [{ name: 'Staff', id: 'staff' }]} label="Role" helperText={'Staff user access.'} customStyle={{ marginLeft: '0px', marginTop: '0px', marginBottom: '40px' }} />


                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant='outlined' onClick={() => setShowAddStaff(false)}>Cancel</Button>
                    <Button variant='contained' onClick={handleAddStaff}>Save</Button>
                </div>
            </BasicModal>

            <BasicModal open={showAddResource} setOpen={setShowAddResource}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SectionTitle>{mode ? 'Edit' : 'Add'} a Resource</SectionTitle>
                    {mode &&
                        <Button variant='outlined' color="error" onClick={() => handleDeleteResource()}>Delete</Button>
                    }
                </div>
                <InputBlock error={fieldError === 'resource-name'} errorMessage={errorMessage} required label='Name' id={'resource-name'} value={resourceName} setValue={setResourceName} />
                <InputBlock error={fieldError === 'resource-description'} errorMessage={errorMessage} required label='Description' id={'resource-description'} value={resourceDescription} setValue={setResourceDescription} />
                {/* <SelectText value={role} disabled={role === 'owner'} setValue={setRole} options={role === 'owner' ? [{ name: 'Staff', id: 'staff' }, { name: 'Owner', id: 'owner' }] : [{ name: 'Staff', id: 'staff' }]} label="Role" helperText={'Staff user access.'} customStyle={{ marginLeft: '0px', marginTop: '0px', marginBottom: '40px' }} /> */}


                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant='outlined' onClick={() => setShowAddResource(false)}>Cancel</Button>
                    <Button variant='contained' onClick={handleAddResource}>Save</Button>
                </div>
            </BasicModal>



            <BasicModal open={showAddLocation} setOpen={setShowAddLocation}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SectionTitle>Add a Location</SectionTitle>
                </div>

                <InputBlock error={fieldError === 'location-name'} errorMessage={errorMessage} required label='Name' id={'location-name'} value={locationName} setValue={setLocationName} />
                <InputBlock error={fieldError === 'location-address'} errorMessage={errorMessage} required label='Address' id={'location-address'} value={locationAddress} setValue={setLocationAddress} />
                {timings.map(timeslot => <LocationOpeningHours timeslot={timeslot} disabledCondition={false} timingToggleFn={toggleTiming} updatingLocationFn={updateLocationTime} />)}

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant='outlined' onClick={() => setShowAddLocation(false)}>Cancel</Button>
                    <Button variant='contained' onClick={() => { handleAddNewLocation() }}>Save</Button>
                </div>
            </BasicModal>


            {
                isLoading ?
                    <Loading open={isLoading} />
                    : <PaperModified>
                        <BasicTabs tabOptions={tabOptions} currentTab={currentTab} setCurrentTab={setCurrentTab}>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '20px' }}>
                                {tabOptions[currentTab] === 'Staff' ? <>
                                    <Row noHover style={{ border: 'none' }}>
                                        <SectionTitle>Staff Users</SectionTitle>
                                        <Button variant="contained" onClick={() => { setMode(''); setShowAddStaff(true) }}>+</Button>
                                    </Row>
                                    <DataTable
                                        rows={staffData}
                                        columns={columns}
                                        onRowClick={updateModalWithValues}
                                    />
                                </> : tabOptions[currentTab] === 'Business' ? <>
                                    <Row noHover style={{ border: 'none', padding: '10px 0' }}>
                                        <SectionTitle>Business Details</SectionTitle>
                                        <Button variant="contained" disabled={!businessName} onClick={() => { updateBusiness({ mId: business.mId, id: business.id, data: { name: businessName, description: businessDescription } }) }}>Update</Button>
                                    </Row>
                                    <InputBlock error={fieldError === 'business-name'} errorMessage={errorMessage} required label='Business Name' id={'business-name'} value={businessName} setValue={setBusinessName} />
                                    <InputBlock error={fieldError === 'business-description'} errorMessage={errorMessage} multiline minRows={3} label='Business Description' id={'business-description'} value={businessDescription} setValue={setBusinessDescription} />

                                    <Row noHover style={{ border: 'none', padding: '10px 0' }}>
                                        <SectionTitle>Locations</SectionTitle>
                                        <Button variant="contained" onClick={() => { setShowAddLocation(true) }}>Add a Location</Button>
                                    </Row>
                                    {locations.length > 0 && <BasicTabs tabOptions={locations.map(loc => loc.name)} currentTab={currentLocation} setCurrentTab={setCurrentLocation}>
                                        {locations && locations[currentLocation] && <>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                {enableEditingLocation ?
                                                    <InputBlock error={fieldError === 'address'} customStyle={{ marginTop: '50px', width: '320px' }} errorMessage={errorMessage} required label='Address' id={'address'} value={locations[currentLocation].address} setValue={(v) => updateLocationAddress(v)} />
                                                    :
                                                    <Subtitle>{locations[currentLocation].address}</Subtitle>
                                                }
                                                <div style={{ display: 'flex' }}>
                                                    {enableEditingLocation && <Button variant="outlined" style={{ marginRight: '20px' }} onClick={() => { setEnableEditingLocation(false); setLocations([...business.locations]) }}>Cancel</Button>}
                                                    <Button variant={!enableEditingLocation ? "outlined" : "contained"} onClick={() => { !enableEditingLocation ? setEnableEditingLocation(!enableEditingLocation) : handleUpdateLocations() }}>{!enableEditingLocation ? 'Edit' : 'Save'}</Button>
                                                </div>
                                            </div>
                                            {locations[currentLocation].timings.map(timeslot =>
                                                <LocationOpeningHours timeslot={timeslot} disabledCondition={!enableEditingLocation} timingToggleFn={toggleTiming} updatingLocationFn={updateLocationTime} />)}
                                        </>}
                                    </BasicTabs>}
                                </> : <>
                                    <Row noHover style={{ border: 'none' }}>
                                        <SectionTitle>Resources</SectionTitle>
                                        <Button variant="contained" onClick={() => { setMode(''); setShowAddResource(true) }}>Add</Button>
                                    </Row>
                                    <DataTable
                                        rows={resources}
                                        columns={resourceColumns}
                                        onRowClick={updateResourceModalWithValues}
                                    />
                                </>}
                            </div>
                        </BasicTabs>
                    </PaperModified>}

        </BodyPadding>
    </Body >)
}


const LocationOpeningHours = ({ timeslot, disabledCondition, timingToggleFn, updatingLocationFn }) => {

    return (<div key={timeslot.day} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {timeslot.day === 'Monday' && <Subtitle style={{ fontSize: '18px' }}>Opening hours</Subtitle>}
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <ControlledSwitches checked={!timeslot.closed} disabled={disabledCondition} setChecked={() => timingToggleFn(timeslot.day, timeslot.closed)} />
            <Subtitle style={{ fontSize: '16px' }}>{timeslot.day}</Subtitle>
        </div>
        {!timeslot.closed && <div style={{ display: 'flex' }}>
            <SelectText value={timeslot.start} disabled={disabledCondition} setValue={(time) => { updatingLocationFn(timeslot.day, 'start', time) }} options={timeslotOptions} label="Start time" helperText={''} />
            <SelectText value={timeslot.end} disabled={disabledCondition} setValue={(time) => { updatingLocationFn(timeslot.day, 'end', time) }} options={timeslotOptions} label="End time" helperText={''} />
        </div>}
    </div>)
}

export default Settings