// export const serverUrl = 'http://localhost:8080/'
export const serverUrl = 'https://bookaclub-server-czhfygf2ia-km.a.run.app/'

export const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const timeslotOptions = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((v, index) =>
    [
        { name: `${v}:00${index > 12 ? 'pm' : 'am'}`, id: 0 + (index * 60) },
        { name: `${v}:05${index > 12 ? 'pm' : 'am'}`, id: 5 + (index * 60) },
        { name: `${v}:10${index > 12 ? 'pm' : 'am'}`, id: 10 + (index * 60) },
        { name: `${v}:15${index > 12 ? 'pm' : 'am'}`, id: 15 + (index * 60) },
        { name: `${v}:20${index > 12 ? 'pm' : 'am'}`, id: 20 + (index * 60) },
        { name: `${v}:25${index > 12 ? 'pm' : 'am'}`, id: 25 + (index * 60) },
        { name: `${v}:30${index > 12 ? 'pm' : 'am'}`, id: 30 + (index * 60) },
        { name: `${v}:35${index > 12 ? 'pm' : 'am'}`, id: 35 + (index * 60) },
        { name: `${v}:40${index > 12 ? 'pm' : 'am'}`, id: 40 + (index * 60) },
        { name: `${v}:45${index > 12 ? 'pm' : 'am'}`, id: 45 + (index * 60) },
        { name: `${v}:50${index > 12 ? 'pm' : 'am'}`, id: 50 + (index * 60) },
        { name: `${v}:55${index > 12 ? 'pm' : 'am'}`, id: 55 + (index * 60) },
    ]).flat()

export const newLocationOpeningHours = [
    { start: 540, end: 1020, closed: true, day: 'Monday' },
    { start: 540, end: 1020, closed: true, day: 'Tuesday' },
    { start: 540, end: 1020, closed: true, day: 'Wednesday' },
    { start: 540, end: 1020, closed: true, day: 'Thursday' },
    { start: 540, end: 1020, closed: true, day: 'Friday' },
    { start: 540, end: 1020, closed: true, day: 'Saturday' },
    { start: 540, end: 1020, closed: true, day: 'Sunday' },
]

export const dayIndexToDay = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
}